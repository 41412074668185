const closestParent = require('./src/utils/closestParent');
const sendGaEvent = require('./src/utils/sendGaEvent');
const sendDataLayer = require('./src/utils/sendDataLayer');

exports.onRouteUpdate = ({ location }) => {
  // sendGaEvent('panel', 'view', true, location.pathname);

  // check if clicked element has 'data-event-label' and send event to GA
  const checkForLayer = event => {
    const element = closestParent(event.target, '[gtm-event-label]', true);
    if (element) {
      const eventLabel = element.getAttribute('gtm-event-label');
      const eventCategory = element.getAttribute('gtm-event-category');
      const eventAction = element.getAttribute('gtm-event-action');
      sendDataLayer('gtm.event', eventCategory, eventAction, eventLabel);
    }
  };

  document.addEventListener('click', checkForLayer, false);
};
