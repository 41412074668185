// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-connect-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/connect.jsx" /* webpackChunkName: "component---src-pages-connect-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-styleguide-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/styleguide.jsx" /* webpackChunkName: "component---src-pages-styleguide-jsx" */),
  "component---src-pages-your-playlist-jsx": () => import("/opt/atlassian/pipelines/agent/build/src/pages/your-playlist.jsx" /* webpackChunkName: "component---src-pages-your-playlist-jsx" */)
}

