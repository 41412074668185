function sendDataLayer(event, eventCategory, eventAction, eventLabel) {
  if (window.dataLayer) {
    // console.log(event, eventCategory, eventAction, eventLabel);
    window.dataLayer.push({ event, eventCategory, eventAction, eventLabel });
  } else {
    console.err('send dataLayer event', { event, eventCategory, eventAction, eventLabel });
  }
}

module.exports = sendDataLayer;
